import React, { ReactElement, useState, useEffect } from 'react';
import { format } from 'date-fns';
import {useParams} from 'react-router-dom';
// @mui
import { Avatar } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Box, Card, CardContent, Grid, Paper, Skeleton, Typography } from '@mui/material';
// components
import Scrollbar from 'components/Scrollbar';
//services
import { useNotify } from 'services/notify';
import { useIsMounted } from 'hooks/useIsMounted';
import useResponsive from 'hooks/useResponsive';

import { ProcesoModuleService } from '../proceso';
import { ProcesoFormModel } from '../proceso/ProcesoModuleService';
import ProcesoTimeline from 'modules/contra/procesodetalle/components/ProcesoTimeline';
//const
import { ESTADO_C1, ESTADO_D } from 'constants/colors';
import { STORAGE_LOCAL } from 'constants/enums';
import { STORAGE_URL } from 'config/app-config';
import { formatearTexto, getAvatarURL } from 'utils';


export type procesoDetalleProps = {
    id?: string;
    titulo                  : string;
    subtitulo               : string;
    descripcion             : string;
    tiempo                  : string;
    notificacion            : boolean;
    notificacion_solicitante: boolean;
    observacion             : string;
    fecha                   : string;
    fecha_limite            : string;
    type                    : string;
    fecha_envio             : string;
    imagen                  : string;
    estado                  : string;
    paso                    : string;

    usuario_id  : string;
    proceso_id  : string;
}

type cardProcesoItemProps = {
    props:{
        title       : string;
        paso        : string;
        description : string;
        image       : string;
        place       : string;
        color       : boolean;
        notificacion: boolean;
    }
};

const FORMAT = 'dd/MM/yyyy HH:mm';

export const ProcesoDetalleModule = (): ReactElement => {
    const notify = useNotify();
    const params = useParams();
    const isMounted = useIsMounted();

    const ID_PROCESO = params.id || '';

    const [procesoData, setProcesoData] = useState<ProcesoFormModel>();

    const [tableParams, setTableParams] = useState<procesoDetalleProps[]>();

    const [isloading, setIsloading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            setIsloading(true);

            const procesoFormResponse = await ProcesoModuleService.getProcesoFormData(ID_PROCESO);
            if (!procesoFormResponse.success) return notify.error(procesoFormResponse.msg);

            const newFormModel: ProcesoFormModel | null = procesoFormResponse.data || null;
            ProcesoModuleService.getTableProcesoDetalle(ID_PROCESO).then((result: any) => {
                setIsloading(false);
                if (!result || !result.success) return;
                setTableParams(result.rows || []);
            });

            if (isMounted()) {
                if (newFormModel !== null)
                    setProcesoData(newFormModel);
            }
        };
        if(ID_PROCESO) fetchData();
    }, [ID_PROCESO]);

    function CardProcesoItem({ props }: cardProcesoItemProps) {
        const { image, title, description, place, color, paso, notificacion } = props;
        const lineasTexto = formatearTexto(place);

        return (
            <Card sx={{ maxWidth: 500, borderRadius: '12px', padding: '8px', margin: '8px', marginTop: '16px', backgroundColor: 'primary.lighter' }}>
                <CardContent sx={{ p: 1 }}>
                    <Typography
                        variant="body2"
                        component="div"
                        sx={{
                            marginBottom  : 1,
                            lineHeight    : 1,
                            display       : 'flex',
                            color         : 'primary.darker',
                            alignItems    : 'flex-start',
                            fontWeight    : 'bold',
                            justifyContent: 'space-between'
                        }}
                    >
                        Paso: {paso}
                        <Avatar
                            alt={title}
                            src={getAvatarURL(image)}
                            sx={{ width: 48, height: 48, float: 'right', top: 2, bottom: 2 }}
                        />
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" sx={{ marginLeft: 1, textAlign: 'left', marginBottom: 1 }}>
                        {lineasTexto.map((linea, index) => {
                            if(index===0) return (
                                <p key={index}><b>Tiempo de Respuesta:</b>&nbsp;&nbsp;{linea}</p>
                            );
                            return (
                                    <p key={index}>{linea}</p>
                                )}
                            )}
                    </Typography>
                    <Paper elevation={0} sx={{ p: 2}}>
                        <Typography variant="body2" sx={{ color: 'primary.darker', fontSize: '12px'}}>
                            {description}
                        </Typography>
                    </Paper>
                </CardContent>
            </Card>
        );
    }


    const isMobile = useResponsive('down', 'md');
    return (
        <>
            <Box mt={1}>
                <Paper elevation={3} sx={{backgroundColor: "#F4F6F8", p: 2}}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            height: '100%'
                          }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Card sx={{  backgroundColor: '#FFE16A', borderRadius: '12px', padding: '16px' }}>
                                <CardContent>
                                    {
                                        isloading ? (
                                            <>
                                                <Skeleton sx={{ my: 3 }} />
                                                <Skeleton sx={{ my: 3 }} />
                                                <Skeleton sx={{ my: 3 }} />
                                                <Skeleton sx={{ my: 3 }} />
                                            </>
                                        ): (
                                            <>
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                                    <LocalOfferIcon sx={{ fontSize: 16, marginRight: 1 }} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        Proceso
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" component="div" fontWeight="bold">
                                                    {procesoData?.objeto_contratacion || ""}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" marginTop={1}>
                                                    {procesoData?.cuce || ""}
                                                </Typography>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {format ( new Date(procesoData?.fecha_registro || new Date() ), FORMAT)}
                                                    </Typography>
                                                </Box>
                                            </>
                                        )
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Card sx={{  backgroundColor: '#DFE3E8', borderRadius: '12px', padding: '16px' }}>
                                <CardContent>
                                {
                                    isloading ? (
                                        <>
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                        </>
                                    ): (
                                        <>
                                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                                <LocalActivityIcon sx={{ fontSize: 16, marginRight: 1 }} />
                                                <Typography variant="body2" color="text.secondary">
                                                    Detalle
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" component="div" fontWeight="bold">
                                                {procesoData?.modalidad_descripcion || ""}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" marginTop={1}>
                                                {procesoData?.codigo_interno_entidad || ""}
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    {procesoData?.gestion || ""}
                                                </Typography>
                                            </Box>
                                            </>
                                    )
                                }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* PANEL CENTRAL */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={7} md={7}>
                            <Box mt={2}>
                                {isloading ? (
                                        <>
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                        </>
                                    ) : (
                                        tableParams && (
                                            <ProcesoTimeline
                                                title="Linea temporal del proceso"
                                                list={tableParams.map((item, index) => ({
                                                    index      : index,
                                                    id         : item.id,
                                                    title      : item.titulo,
                                                    subtitle   : item.subtitulo,
                                                    type       : ESTADO_D[item.estado],
                                                    color      : ESTADO_C1[item.estado],
                                                    time1      : item.fecha,
                                                    time2      : item.fecha_limite,
                                                    fecha_envio: item.fecha_envio,
                                                    state      : item.estado,
                                                    label1     : ' ',
                                                    label2     : 'fecha limite: ',
                                                }))}
                                            />
                                        )
                                )}
                            </Box>
                        </Grid>
                        {

                            !isMobile && <Grid item xs={12} sm={5} md={5}>
                                            <Box mt={2}>
                                                {
                                                    isloading ? (
                                                        <>
                                                            <Skeleton sx={{ my: 3 }} />
                                                            <Skeleton sx={{ my: 3 }} />
                                                            <Skeleton sx={{ my: 3 }} />
                                                            <Skeleton sx={{ my: 3 }} />
                                                        </>
                                                    ) : (
                                                            <Paper elevation={3} sx={{ backgroundColor: '#F9FAFB' }}>
                                                                <Typography variant="h6" sx={{ pl: 2, pt: 1, pb: 1}}>Observaciones al proceso</Typography>
                                                                <Box sx={{
                                                                        overflowY: 'auto',
                                                                        transition: 'height .5s, marginTop .5s',
                                                                        height: 800,
                                                                        borderRadius: 1
                                                                    }}
                                                                >
                                                                    <Scrollbar>
                                                                        {
                                                                            tableParams && tableParams.map((item: procesoDetalleProps) => (
                                                                                <CardProcesoItem
                                                                                    key={item.id}
                                                                                    props={{
                                                                                        title       : item.titulo,
                                                                                        paso        : item.paso,
                                                                                        description : item.observacion,
                                                                                        image       : item.imagen,
                                                                                        place       : item.tiempo,
                                                                                        color       : item.notificacion,
                                                                                        notificacion: item.notificacion_solicitante
                                                                                    }}
                                                                                />
                                                                            ))
                                                                        }
                                                                    </Scrollbar>
                                                                </Box>
                                                            </Paper>
                                                        )
                                                }
                                            </Box>
                                        </Grid>
                        }
                    </Grid>
                </Paper>
            </Box>
        </>
    );
};
