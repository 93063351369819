import React, { useState } from 'react';
// @mui
import { Typography, Link, Box, Paper, Modal, Button, Avatar, Backdrop, CircularProgress } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, timelineItemClasses } from '@mui/lab';

import ModeStandbyIcon from '@mui/icons-material/ModeStandby'; //
import HighlightOffIcon from '@mui/icons-material/HighlightOff'; //
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'; //
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; //
import { fDateTime } from 'utils/formatTime';
// utils
import useResponsive from 'hooks/useResponsive';
import { procesoDetalleProps } from '../ProcesoDetalleModule';
import { useNotify } from 'services/notify';
import { ProcesoModuleService } from 'modules/contra/proceso/ProcesoModuleService';
import { formatearTexto, getAvatarURL } from 'utils';
// ----------------------------------------------------------------------

interface TimeProps {
    isLast: boolean;
    handleOpen: (id: string) => Promise<void>;
    item: {
        id         : string;
        time1      : Date;
        time2      : Date;
        label1     : string;
        label2     : string;
        title      : string;
        color      : string;
        state      : string;
        subtitle   : string;
        fecha_envio: string;
        type: 'inherit' | 'grey' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    };
}

function TimeLineProcesoItem({ item, isLast, handleOpen }: TimeProps) {
    const { type, title, subtitle, time1, time2, label1, label2, color, state, fecha_envio, id  } = item;
    const stateToIcon: any = {
        EN_PROCESO: <ShoppingCartCheckoutIcon fontSize='small' />,
        ATENDIDO: <CheckCircleOutlineIcon fontSize='small' />,
        PENDIENTE: <ModeStandbyIcon fontSize='small' />,
        ATRASADO: <HighlightOffIcon fontSize='small' />,
    };
    const isMobile = useResponsive('down', 'md');
    if(isMobile)
        return (
            <TimelineItem
                onClick={() => handleOpen(id)}
                style={{ cursor: 'pointer' }}
                sx={{
                    '&:hover': {
                      animation: 'bounce 0.3s ease-in-out',
                    },
                    '@keyframes bounce': {
                      '0%, 20%, 50%, 80%, 100%': {
                        transform: 'translateY(0)',
                      },
                      '40%': {
                        transform: 'translateY(-10px)',
                      },
                      '60%': {
                        transform: 'translateY(-5px)',
                      },
                    },
                }}
            >
                <TimelineSeparator>
                    <TimelineDot color={type} variant='outlined'></TimelineDot>
                    {isLast ? null : <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent sx={{ p:0, pl: 1, mb: 4, mt: 1, lineHeight: 0.625 }}>
                    <Typography component="span" sx={{ color: '#152e4d', fontSize: '12px' }}>
                        { label1 + fDateTime(time1)}
                    </Typography>
                    <Paper
                        elevation={8}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 1
                        }}
                    >
                        <Box display="flex" flexDirection="column" sx={{ py:2, pl: 2, pb: 1, pr: 2}} justifyContent='space-between'>
                            { fecha_envio && <Typography component="span"  sx={{ color: 'text.secondary', fontSize: '11px'}}>
                                    {" Enviado el "+fecha_envio}
                                </Typography>
                            }
                            <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '12px', fontWeight: 'bold' }}>{title}</Typography>
                            <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '12px', fontStyle: 'italic', mt: 2}}>
                                { label2 + fDateTime(time2)}
                            </Typography>
                        </Box>
                        <Box sx={{backgroundColor: color, borderTopRightRadius: '4px', borderBottomRightRadius: '4px', textAlign: 'center', pt: 1, px: 1, pb: 1, display: 'flex', justifyContent: 'center'}}>
                            <Typography component="span" sx={{ color: 'white', fontSize: '12px', writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}>
                                {subtitle}
                            </Typography>
                        </Box>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        );
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot color={type}>
                    {
                        stateToIcon[state]
                    }
                </TimelineDot>
                {isLast ? null : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Paper
                    elevation={8}
                    sx={{
                        padding: "24px 16px 16px 16px",
                        margin: "8px 6px",
                    }}
                >
                    <Typography variant="subtitle2" sx={{ lineHeight: 1.125 }}>{title}</Typography>
                    <Typography component="span" sx={{ color: color, fontSize: '12px', fontWeight: 'bold'}}>
                        {subtitle}
                    </Typography>
                    { fecha_envio && <Typography component="span"  sx={{ color: 'text.secondary', fontSize: '12px'}}>
                            {" enviado el "+fecha_envio}
                        </Typography>
                    }
                    <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '12px', fontStyle: 'italic'}}>
                        { label1 + fDateTime(time1)}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '12px', fontStyle: 'italic'}} alignContent='flex-end'>
                        { label2 + fDateTime(time2)}
                    </Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
}

// ----------------------------------------------------------------------

type Props = {
    title?: string;
    subtitle?: string;
    subheader?: string;
    list: Array<any>;
};

type modalProps = {
    title       : string;
    paso        : string;
    description : string;
    image       : string;
    place       : string;
    color       : string;
    notificacion: string;
};

type ActividadFormModel = {
    id                      ?: number;
    titulo                   : string;
    descripcion              : string;
    paso                     : number;
    tiempo                   : string;
    notificacion             : boolean;
    notificacion_solicitante?: boolean;
    observacion              : string;
    estado                   : string;
    fecha                    : Date;
    fecha_limite             : Date;
    fecha_envio?             : Date;
    imagen?                  : string;
    usuario_id?              : string;
    proceso_id?              : string;
};

export default function ProcesoTimeline({ title, subtitle, subheader, list, ...other }: Props) {
    const notify = useNotify();

    const isMobile = useResponsive('down', 'md');
    const [open, setOpen] = useState(false);
    const [formModel, setFormModel] = useState<ActividadFormModel>();
    const [loading, setLoading] = useState(false);

    const handleClose = () => setOpen(false);

    const handleClickOpen = async (id: string) => {
        setLoading(true);
        const actividadFormResponse = await ProcesoModuleService.getActividadFormData(id);
        if (!actividadFormResponse.success) return notify.error(actividadFormResponse.msg);
        const newFormModel = actividadFormResponse.data;
        setLoading(false);
        setFormModel(newFormModel);
        setOpen(true);
    };

    const lineasTexto = formModel && formatearTexto(formModel.tiempo) || [];

    return (
        <Paper elevation={3} sx={{ backgroundColor: '#F9FAFB' }}>
            <Box p={2}>
                <Typography variant="h6">{title}</Typography>
            </Box>
            <Timeline
                sx={{
                    ...(isMobile && {
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }),
                  }}
                position={isMobile?'right':'alternate'}
            >
                {list.map((item, index) => (
                    <TimeLineProcesoItem key={item.index} item={item} isLast={index === list.length - 1} handleOpen={handleClickOpen} />
                ))}
            </Timeline>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4 }}>
                    <Typography
                        variant="body2"
                        component="div"
                        sx={{
                            marginBottom  : 1,
                            lineHeight    : 1,
                            display       : 'flex',
                            color         : 'primary.darker',
                            alignItems    : 'flex-start',
                            fontWeight    : 'bold',
                            justifyContent: 'space-between'
                        }}
                    >
                        Paso: {formModel && formModel.paso}
                        <Avatar
                            alt={title}
                            src={getAvatarURL(formModel && formModel?.imagen || "")}
                            sx={{ width: 48, height: 48, float: 'right', top: 2, bottom: 2 }}
                        />
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" sx={{ marginLeft: 1, textAlign: 'left', marginBottom: 1 }}>
                        {lineasTexto.map((linea, index) => {
                            if(index===0) return (
                                <p key={index}><b>Tiempo de Respuesta:</b>&nbsp;&nbsp;{linea}</p>
                            );
                            return (
                                    <p key={index}>{linea}</p>
                                )}
                            )}
                    </Typography>
                    <Paper elevation={0} sx={{ p: 2}}>
                        <Typography variant="body2" sx={{ color: 'primary.darker', fontSize: '12px'}}>
                            {formModel && formModel.observacion}
                        </Typography>
                    </Paper>
                    <Button onClick={handleClose} sx={{ mt: 2, float: 'right' }}>Close</Button>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Paper>
    );
}
